define("discourse/plugins/discourse-encrypt/discourse/components/encrypt-enable-dropdown", ["exports", "@ember/object", "@ember-decorators/component", "I18n", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _object, _component, _I18n, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EncryptEnableDropdown = dt7948.c(class EncryptEnableDropdown extends _dropdownSelectBox.default {
    get content() {
      const content = [];
      content.push({
        id: "import",
        icon: "file-import",
        name: this.importIdentity ? this.isEncryptEnabled ? _I18n.default.t("encrypt.preferences.use_paper_key") : _I18n.default.t("encrypt.preferences.generate_key") : _I18n.default.t("encrypt.preferences.import")
      });
      if (this.isEncryptEnabled) {
        content.push({
          id: "reset",
          icon: "trash-alt",
          name: _I18n.default.t("encrypt.reset.title")
        });
      }
      return content;
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [(0, _object.computed)("importIdentity", "isEncryptEnabled")]))();
  }, [(0, _component.classNames)("encrypt-enable-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: "bars",
    showFullTitle: false
  })]);
  var _default = _exports.default = EncryptEnableDropdown;
});