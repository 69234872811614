define("discourse/plugins/discourse-encrypt/discourse/components/encrypted-post-timer-dropdown", ["exports", "@ember/object", "@ember/object/computed", "@ember-decorators/component", "I18n", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _object, _computed, _component, _I18n, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TIMER_OPTIONS = [{
    id: "",
    name: _I18n.default.t("encrypt.time_bomb.never")
  }, {
    id: "3",
    name: _I18n.default.t("encrypt.time_bomb.3_minutes")
  }, {
    id: "60",
    name: _I18n.default.t("encrypt.time_bomb.1_hour")
  }, {
    id: "180",
    name: _I18n.default.t("encrypt.time_bomb.3_hours")
  }, {
    id: "720",
    name: _I18n.default.t("encrypt.time_bomb.12_hours")
  }, {
    id: "1440",
    name: _I18n.default.t("encrypt.time_bomb.24_hours")
  }, {
    id: "4320",
    name: _I18n.default.t("encrypt.time_bomb.3_days")
  }, {
    id: "10080",
    name: _I18n.default.t("encrypt.time_bomb.7_days")
  }];
  const EncryptedPostTimerDropdown = dt7948.c(class EncryptedPostTimerDropdown extends _dropdownSelectBox.default {
    static #_ = (() => dt7948.g(this.prototype, "hidden", [(0, _computed.empty)("content")]))();
    #hidden = (() => (dt7948.i(this, "hidden"), void 0))();
    get content() {
      if (this.topicDeleteAt) {
        return TIMER_OPTIONS.filter(option => {
          return option.id.length > 0 && moment().add(option.id, "minutes") < moment(this.topicDeleteAt);
        });
      } else {
        return TIMER_OPTIONS;
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "content", [(0, _object.computed)("topicDeleteAt")]))();
  }, [(0, _component.classNameBindings)("hidden:hidden"), (0, _component.classNames)("encrypted-post-timer-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: "discourse-trash-clock",
    showFullTitle: true
  })]);
  var _default = _exports.default = EncryptedPostTimerDropdown;
});