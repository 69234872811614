define("discourse/plugins/discourse-encrypt/discourse/components/encrypt-preferences-dropdown", ["exports", "@ember-decorators/component", "I18n", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _component, _I18n, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EncryptPreferencesDropdown = dt7948.c(class EncryptPreferencesDropdown extends _dropdownSelectBox.default {
    content = (() => [{
      id: "export",
      icon: "file-export",
      name: _I18n.default.t("encrypt.export.title")
    }, {
      id: "managePaperKeys",
      icon: "ticket-alt",
      name: _I18n.default.t("encrypt.manage_paper_keys.title")
    }, {
      id: "decryptAll",
      icon: "unlock",
      name: _I18n.default.t("encrypt.decrypt_all.button")
    }, {
      id: "rotate",
      icon: "sync",
      name: _I18n.default.t("encrypt.rotate.title")
    }, {
      id: "reset",
      icon: "trash-alt",
      name: _I18n.default.t("encrypt.reset.title")
    }])();
  }, [(0, _component.classNames)("encrypt-preferences-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: "wrench",
    showFullTitle: false
  })]);
  var _default = _exports.default = EncryptPreferencesDropdown;
});